* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  background-color: #222;
  font-family: "Bree Serif", serif;
  color: white;
}

h1 {
  font-size: 3.5rem;
}

.container {
  padding: 2rem;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.banner .container,
.settings,
.details .feature-list,
footer,
.download .container {
  display: flex;
}

.banner .container > div,
.settings > div {
  flex: 1 1;
  width: 50%;
}

.hero {
  padding: 5vh 10vw;
}

.phone {
  text-align: center;
  position: relative;
}

.details {
  background-color: #444;
  text-align: center;
}

.details h2,
.download h2 {
  font-size: 2.8rem;
}

.details .feature-list,
.details .settings-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  flex: 1 1;
  padding: 1rem 1.5rem;
}

.settings-list-container {
  align-self: center;
  transform: translateY(-20%);
}

.feature-list li h4 {
  font-size: 1.5rem;
}

.settings-list {
  text-align: left;
  align-self: center;
}

.feature-list li h3,
.settings-list h3 {
  font-size: 1.8rem;
}

.settings-list h3 {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.download {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.download .container {
  justify-content: center;
  align-items: center;
}

.download .container p,
.download .container h2 {
  padding: 0 3rem;
}

main .loading {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: white;
  font-size: 3rem;
}

footer {
  padding: 3rem 0;
  justify-content: space-between;
  text-align: center;
}

footer > div {
  flex: 1 1;
  margin: 1rem 0;
}

footer a {
  color: white;
  text-decoration: none;
}

main.loaded .loading {
  display: none;
}

@media (max-width: 1280px) {
  .container {
    padding: 2rem;
    max-width: 1100px;
  }
}

@media (max-width: 960px) {
  .container {
    padding: 2rem;
    max-width: 800px;
  }

  .banner .container,
  .details .feature-list,
  .settings,
  footer,
  .download .container {
    flex-direction: column;
    text-align: center;
  }

  .banner .container > div,
  .settings > div {
    width: 100%;
  }

  .hero {
    padding-top: 0;
  }

  .settings-list-container {
    transform: none;
  }
  .settings-list {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 2rem 1rem;
  }
}
